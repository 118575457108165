import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import '../styles/prism';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import { create } from 'apisauce';
import Constants from '../../static/Constants';
import { ThreeDots } from 'react-loader-spinner';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { Slide } from 'react-reveal';
import Twitter from '@material-ui/icons/Twitter';
//import Instagram from '@material-ui/icons/Instagram';
import NavPills from '../components/NavPills/NavPills';
import FacebookIcon from '@material-ui/icons/Facebook';
//import InstagramIcon from '@material-ui/icons/Instagram';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
// import MapParallax from '../components/MapView/MapParallax.jsx';
// import styles from './country.styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MapWrapper from '../components/GoogleMap/MapWrapper';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });
const baseURL = Constants.apiUrl;
const imageFolderPath = '/resources/CountryImages/';
const apiSauceInstanceMapBox = create({
  baseURL: Constants.mapBoxGeoCodingUrl,
});

// const {
//   Instagram_Twitter,
// } = styles;

const Partner = ({ ...props }) => {
  const { pageContext } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { headerLogo, headerLogoScroll, screenName } = (data || {}).node || {};

  const [institutionData, setInstitutionData] = useState(null);
  const [loading, setLoading] = useState(false);
  //const [galleryLoading, setGalleryLoading] = useState(false);
  const [institutionGallery, setInstitutionGallery] = useState([]);
  const [coordinates, setCoordinates] = useState([22.7578, 5.9806]);
  const [partnersTeam, setPartnerTeam] = useState([]);
  const [gallaryImagesCount,SetGallaryImagesCount] = useState();

  const settings = {
    dots: true,
    infinite: gallaryImagesCount > 3,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('id')
    ) {
      setLoading(true);
      const institutionID = parseInt(
        window.location.search.replace('?id=', '')
      );
      apiSauceInstance
        .get(`${Constants.getInstitutionByID}/${institutionID}`)
        .then(({ data, status }) => {
          if (status !== 400) {
            setInstitutionData({
              ...data,
              telephone: [
                ...data.institutionContact.filter(
                  contact => contact.contactType.contactTypeID === 5
                ),
              ],
              email: [
                ...data.institutionContact.filter(
                  contact => contact.contactType.contactTypeID === 6
                ),
              ],
              twitter: [
                ...data.institutionContact.filter(
                  contact => contact.contactType.contactTypeID === 4
                ),
              ],
              facebook: [
                ...data.institutionContact.filter(
                  contact => contact.contactType.contactTypeID === 1
                ),
              ],
              linkedIn: [
                ...data.institutionContact.filter(
                  contact => contact.contactType.contactTypeID === 2
                ),
              ],
              skype: [
                ...data.institutionContact.filter(
                  contact => contact.contactType.contactTypeID === 3
                ),
              ],
            });
            if ((data || {}).institutionTeamMember.length > 0) {
              setPartnerTeam(
                (data || {}).institutionTeamMember.map(member => ({
                  name: member.firstName + ' ' + member.lastName,
                  designation:
                    member.designation.trim().length > 0
                      ? member.designation
                      : 'NA',
                  email: [
                    ...member.institutionTeamMemberContact.filter(
                      item => item.contactTypeID === 6
                    ),
                  ],
                  phone: [
                    ...member.institutionTeamMemberContact.filter(
                      item => item.contactTypeID === 5
                    ),
                  ],
                }))
              );
            }
            if ((data || {}).longitude && (data || {}).latitude) {
              setCoordinates([data.latitude, data.longitude]);
            }
          }
          setLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (
      institutionData &&
      !institutionData.longitude &&
      !institutionData.latitude
    ) {
      const searchText = `${institutionData.country.countryName} ${
        institutionData.region ? institutionData.region.regionName : ''
      } ${institutionData.city ? institutionData.city.cityName : ''}`;

      const url = `/${encodeURI(searchText)}.json?access_token=${
        Constants.mapBoxApiKey
      }`;
      apiSauceInstanceMapBox
        .get(url)
        .then(mapCoordinates => {
          if (mapCoordinates.data && mapCoordinates.data.features.length > 0) {
            if (
              mapCoordinates.data.features[0].geometry &&
              mapCoordinates.data.features[0].geometry.coordinates
            ) {
              institutionData.longitude =
                mapCoordinates.data.features[0].geometry.coordinates[0] || 0;
              institutionData.latitude =
                mapCoordinates.data.features[0].geometry.coordinates[1] || 0;
              setCoordinates([
                institutionData.latitude,
                institutionData.longitude,
              ]);
            }
          }
          setLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          setLoading(false);
        });
    }
  }, [institutionData]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.search.includes('id')
    ) {
      //setGalleryLoading(true);
      const institutionID = parseInt(
        window.location.search.replace('?id=', '')
      );
      apiSauceInstance
        .get(`${Constants.getInstitutionImagesByID}/${institutionID}`)
        .then(({ data, status }) => {
          if (status !== 400) {
            setInstitutionGallery(data);
            const ig = data.filter(i => i.imageTypeID == 3);
            SetGallaryImagesCount(ig.length);
          }
          //setGalleryLoading(false);
        })
        .catch(error => {
          if (error) {
            //do something
          }
          //setGalleryLoading(false);
        });
    }
  }, []);

  const logos = pageContext.logos || [];
  const { t, ready } = useTranslation();

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ', ';
    }
    return output;
  };

  const getCoverImage = () => {
    if (institutionData != null) {
      let objCover =
        institutionGallery.length > 0 &&
        institutionGallery.find(o => o.imageTypeID === 2); //Cover Image
      if (objCover) {
        return objCover.institutionImageSource;
      } else {
        if (institutionData != null) {
          let countryName = '';
          countryName = institutionData.country.countryName
            .split(' ')
            .join('_');
          let imageUrl = `${baseURL}/${imageFolderPath}${institutionData.country.countryID}/${countryName}.jpg`;
          return imageUrl;
        }
      }
    } else {
      return '';
    }
  };

  const getLogoImage = () => {
    let objLogo =
      institutionGallery.length > 0 &&
      institutionGallery.find(o => o.imageTypeID === 1); //logo Image
    if (objLogo) {
      return objLogo.institutionImageSource;
    } else {
      return '';
    }
  };

  // const getGalleryImage = () => {
  //   const gallaryImages = [];
  //   let objCover =
  //     institutionGallery.length > 0 &&
  //     institutionGallery.filter(o => o.imageTypeID === 3); //Gallery Image
  //   if (objCover) {
  //     console.log('data', institutionGallery);
  //     return objCover.institutionImageSource;
  //   } else {
  //     return '';
  //   }
  // };

  let countries_data =
    t('countries_data', {
      returnObjects: true,
    }) || [];

  const getCountryDataName = countryID => {
    let id = countryID;
    if (!ready) {
      return 'Loading...';
    } else {
      let data = countries_data.find(({ countryID }) => countryID == id);
      return data ? data?.countryName : '';
    }
  };

  return (
    <Layout
      programLink={programLink}
      imageName={
        getCoverImage() != '' ? getCoverImage() : 'Manufacturing.png'
        // institutionData
        //   ? institutionData.institutionLogo &&
        //     institutionData.institutionLogo.length > 0
        //     ? institutionData.institutionLogo
        //     : 'Manufacturing.png'
        //   : 'Manufacturing.png'
      }
      customView={
        getCoverImage()
        // institutionData
        //   ? institutionData.institutionLogo
        //     ? true
        //     : false
        //   : false
      }
      headerLinks={headerLinks}
      logos={logos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[
        { url: '/', key: 'home', name: 'Home' },
        {
          url: '/partners',
          key: 'ipi',
          name: 'Investment Promotion Institutions',
        },
      ]}
      currentPage={institutionData ? institutionData.institutionName : ''}
      screenName={screenName}
    >
      <div className="container-max">
        <div className="countries-title">
          {data && institutionData ? (
            <div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <div>
                    <span className="logoImage">
                      <img src={getLogoImage()} alt=""></img>
                    </span>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={4}>
                  <div className="project-title">
                    <h3>{t('Name')}</h3>
                    <p>{institutionData.institutionName}</p>
                  </div>
                  <div className="investment-proposal">
                    <h3>{t('About')}</h3>
                    <p>{institutionData.institutionDescription}</p>
                  </div>
                  {(partnersTeam || []).length > 0 ? (
                    <div className="investment-proposal">
                      <h3>{t('our_team')}</h3>
                      <GridContainer
                        spacing={4}
                        className="partners-team-wrapper"
                      >
                        {(partnersTeam || []).map((team, key) => (
                          <GridItem md={6} key={team.name + key}>
                            <div className="team-member">
                              <div className="name">{team.name}</div>
                              <div className="designation">
                                {team.designation}
                              </div>
                              <div className="social-media">
                                <ul>
                                  {team.email.length > 0 ? (
                                    <li>
                                      <MailIcon />
                                      <p>{team.email[0]?.contactDetail}</p>
                                    </li>
                                  ) : null}
                                  {team.phone.length > 0 ? (
                                    <li>
                                      <PhoneIcon />
                                      <p>{team.phone[0]?.contactDetail}</p>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          </GridItem>
                        ))}
                      </GridContainer>
                    </div>
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <div>
                    {institutionData ? (
                      <div className="org-details">
                        <h3>{t('partner_details')}</h3>
                        <div className="org-summary">
                          <ul>
                            <li>
                              <div className="summary-item-logo">
                                <LanguageIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('website')}
                                </p>
                                <p>
                                  {institutionData.website ? (
                                    <a
                                      href={institutionData.website}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      {institutionData.website}
                                    </a>
                                  ) : (
                                    'N/A'
                                  )}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="summary-item-logo">
                                <RoomIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('address')}
                                </p>
                                <p>
                                  {institutionData.address
                                    ? institutionData.address
                                    : 'N/A'}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="summary-item-logo">
                                <AlternateEmailIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('Email')}
                                </p>
                                <p>
                                  <ul>
                                    {institutionData.email &&
                                    institutionData.email.length > 0 ? (
                                      institutionData.email.map(mail => (
                                        <li key={mail.industrialParkContactID}>
                                          {mail.contactDetail}
                                        </li>
                                      ))
                                    ) : (
                                      <li>N/A</li>
                                    )}
                                  </ul>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="summary-item-logo">
                                <PhoneIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('Telephone')}
                                </p>
                                <p>
                                  <ul>
                                    {institutionData.telephone &&
                                    institutionData.telephone.length > 0 ? (
                                      institutionData.telephone.map(phone => (
                                        <li key={phone.industrialParkContactID}>
                                          {phone.contactDetail}
                                        </li>
                                      ))
                                    ) : (
                                      <li>N/A</li>
                                    )}
                                  </ul>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="summary-item-logo">
                                <FacebookIcon />
                              </div>
                              <div className="summary-item">
                                <p className="summary-item-title">
                                  {t('Facebook')}
                                </p>
                                <p>
                                  {institutionData.facebook &&
                                  institutionData.facebook.length > 0
                                    ? institutionData.facebook.map(facebook => (
                                        <a
                                          key={facebook.industrialParkContactID}
                                          href={facebook.contactDetail}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {`${facebook.contactDetail}`}
                                        </a>
                                      ))
                                    : 'N/A'}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Slide bottom>
                    <NavPills
                      color="customPrimary"
                      alignCenter={true}
                      tabs={[
                        {
                          tabIcon: Twitter,
                          tabContent: (
                            <div className="twitterHeight">
                              {institutionData.twitter &&
                              institutionData.twitter.length > 0 ? (
                                institutionData.twitter.map(twitter => (
                                  <TwitterTimelineEmbed
                                    key={twitter.industrialParkContactID}
                                    sourceType="profile"
                                    screenName={twitter.contactDetail}
                                    options={{ height: 560 }}
                                  />
                                ))
                              ) : (
                                <TwitterTimelineEmbed
                                  sourceType="profile"
                                  screenName={'InvestinACP'}
                                  options={{ height: 560 }}
                                />
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </Slide>
                </GridItem>
              </GridContainer>
            </div>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} className="page-loader-wrapper">
                <div>
                  {loading ? (
                    <div width="100%">
                      <div className="three-dots-loader">
                        <ThreeDots
                          color="#5990ff"
                          height={100}
                          width={100}
                          timeout={10000}
                        />
                      </div>
                    </div>
                  ) : (
                    t('No Institution data to display')
                  )}
                </div>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </div>
      {/* leaflet map view */}
      {/* <MapParallax
        projects={[
          {
            coordinates: coordinates,
            id: 0,
            regionName: institutionData
              ? getAppendedCommaValue(
                  institutionData.region
                    ? institutionData.region.regionName
                    : ''
                ) +
                  getAppendedCommaValue(
                    institutionData.city ? institutionData.city.cityName : ''
                  ) +
                  getCountryDataName(institutionData.country.countryID) || ''
              : '',
            currentPage: 'Partner',
          },
        ]}
      /> */}
      {/* Goole Map view */}
      {!loading ? (
        <MapWrapper
          projects={[
            {
              coordinates: coordinates,
              id: 0,
              regionName: institutionData
                ? getAppendedCommaValue(
                    institutionData.region
                      ? institutionData.region.regionName
                      : ''
                  ) +
                    getAppendedCommaValue(
                      institutionData.city ? institutionData.city.cityName : ''
                    ) +
                    getCountryDataName(institutionData.country.countryID) || ''
                : '',
              currentPage: 'Partner',
            },
          ]}
        />
      ) : (
        ''
      )}
      <div className="container">
        <GridItem xs={12} sm={12} md={12}>
          <div className="row">
            {institutionGallery.length > 0 &&
            institutionGallery.filter(img => img.imageTypeID == 3).length >
              0 ? (
              <div className="project-title">
                <h3>{t('image_gallery')}</h3>
              </div>
            ) : (
              ''
            )}
            <div className="responsive">
              <span className="gallery">
                <Slider {...settings} className="overflow-hidden">
                  {institutionGallery.map((img, index) =>
                    img.imageTypeID == 3 ? (
                      <img
                        src={img.institutionImageSource}
                        alt=""
                        key={index}
                      />
                    ) : (
                      ''
                    )
                  )}
                </Slider>
              </span>
            </div>
          </div>
        </GridItem>
      </div>
    </Layout>
  );
};

export default Partner;

Partner.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
